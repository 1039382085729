<template>
  <div>
    <div class="flex flex-row items-end justify-between">
      <div class="flex flex-row">
        <InputField
          placeholder="Search by name, status or job title"
          label="Filter by"
          :onChange="setKeyword"
          leftIcon
          class="mb-2 w-inputField"
          data-testid="filter"
        />
        <Select
        :options="this.statusOptions"
        :onSelect="setStatusFilter"
        :value="statusFilter"
        :preselectFirst="true"
        label="Status"
        objects
        class="ml-2 min-w-150 h-40 mr-2"
      />
      </div>
      <div>
        <div class="flex flex-row space-x-2">
          <div>
            <Button
              text="Invite to Platform"
              type="secondary"
              size="medium"
              data-testid="invite-button"
              :iconLeft="email"
              @click.native="inviteToPlatform"
            />
          </div>
          <div class="mb-2">
            <Button
              text="Get Upload Template (.CSV)"
              type="secondary"
              size="medium"
              @click.native="downloadTemplate"
            />
          </div>
          <AddNewButton :callback="updateList" :withRole="true" class="z-10" />
        </div>
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} users out of {{ totalUsersCount }}
    </p> 
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <div>
          <button
            class="flex flex-row w-tableIndex items-center focus:outline-none mr-3"
            data-testid="header-0"
          >
            <p class="text-sm-2 text-grey-light">NO.</p>
          </button>
        </div>
        <div class="flex flex-grow w-1/4" data-testid="header-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort('name', 0)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/4 -ml-1" data-testid="header-2">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('title', 1)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/6 -ml-2" data-testid="header-3">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('role', 2)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">SYSTEM ROLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/6 justify-center" data-testid="header-4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('status', 3)"
          >  
            <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <button
          class="flex flex-shrink justify-center w-1/6"
          data-testid="header-5"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
        </button>
      </div>
      <div>
        <VirtualList
          style="max-height: 360px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          data-testid="table"
          :extra-props="{ manage: manageUser }"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import add from "@/assets/img/icons/add.svg";
import email from "@/assets/img/icons/email.svg";
import AddNewButton from "@/views/AdminAssessments/components/AddNewButton";

export default {
  name: "CompaniesUsersTable",
  components: { VirtualList, AddNewButton },
  props: {
    usersCount: { type: Number },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    add,
    email,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    pageNr: 1,
    sorting: null,
    statusFilter: null,
    statusOptions: [
        { label: "All Statuses", id: -1 },
        { label: "Inactive", id: 0 },
        { label: "Active", id: 1 },
        { label: "Invited", id: 2 },
        { label: "Not Invited", id: 3 },
        { label: "Deleted", id: 4 }
      ],
  }),
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      totalUsersCount: (state) => state.companies.totalUsersCount,
    }),
  },
  async mounted() {
    await this.getCompany();
  },
  watch: {
    keyword() {
      this.updateList(true);
    },
    statusFilter() {
      this.updateList(true);
      this.$emit('onChangeStatusFilter', this.statusFilter.id)
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      getCompanyDetails: "companies/getCompanyDetails",
      fetchCompanyUsers: "companies/fetchCompanyUsers",
      downloadTemplate: "utils/downloadTemplate",
      setSelectedUser: "companies/setSelectedUser",
      setSelectedUsersForInvite: "people/setSelectedUsersForInvite",
    }),
    async getCompany() {
      return await this.getCompanyDetails({ id: this.$route.params.companyId });
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
    setStatusFilter(value) {
      this.statusFilter = value;
    },
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.totalUsersCount / 10)) {
        this.pageNr++;
        this.updateList();
      }
    },
    async updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      const payload = {
        keyword: this.keyword.toLowerCase(),
        pageNumber: this.pageNr,
        pageSize: 10,
        sorting: this.sorting,
        allowInviteInactiveUsers: true,
        status: this.statusFilter.id,
      };
      this.fetchCompanyUsers({
        id: this.$route.params.companyId,
        payload,
      }).then((rsp) => {
        let map = rsp.map((item, idx) => ({
          ...item,
          index: (this.pageNr - 1) * 10 + idx,
        }));
        this.mappedList = [...this.mappedList, ...map];
      });
    },
    async fetchAllUsers() {
      this.pageNr = Math.ceil(this.totalUsersCount / 10);
      const payload = {
        keyword: this.keyword.toLowerCase(),
        pageNumber: 1,
        pageSize: this.totalUsersCount,
        sorting: this.sorting,
        allowInviteInactiveUsers: true
      };
      await this.fetchCompanyUsers({
        id: this.$route.params.companyId,
        payload,
      }).then((rsp) => {
        let usersList = rsp.map((item, idx) => ({
          ...item,
          index: idx,
        }));
        this.mappedList = [...usersList];
      });
    },
    manageUser(user) {
      this.setSelectedUser({ user });
      this.$router.push(`/company/${this.currentCompany.id}/users/${user.id}`);
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    async sort(key, index) {
      if (this.pageNr !== Math.ceil(this.totalUsersCount / 10)) {
        await this.fetchAllUsers(false);
      }
      this.resetRotationMatrix(index);
      switch (key) {
        case "name":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.firstName.localeCompare(b.firstName)
          );
          break;
        case "title":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.jobTitle?.localeCompare(b.jobTitle)
          );
          break;
        case "role":
          const getRolesString = (roles) => roles.map(x => x.name).join(', ')
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * (getRolesString(a.roles).localeCompare(getRolesString(b.roles)))
          );
          break;
        case "status":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * (b.status - a.status)
          );
          break;
        default:
          break;
      }
    },
    inviteToPlatform() {
      this.setSelectedUsersForInvite([]);
      this.$router.push(`/company/${this.currentCompany.id}/users/invite`);
    },
  },
};
</script>
<style lang="scss" scoped>
.new-company-btn {
  line-height: 1;
}
</style>
